import { useRecoilValue } from 'recoil'
import {
  currentCustomerApi,
  currentCustomerApiV2,
  currentShopApi,
  customerApi,
  genericApi,
  shopApi,
  tenantApi,
  tenantApiV2,
} from './apiClientState'

export const useGenericApi = () => {
  return useRecoilValue(genericApi)
}

export const useCurrentTenantApi = () => {
  return useRecoilValue(tenantApi)
}

export const useCurrentCustomerApi = () => {
  return useRecoilValue(currentCustomerApi)
}

export const useCustomerApi = (customerId: number) => {
  return useRecoilValue(customerApi(customerId))
}

export const useCurrentShopApi = () => {
  return useRecoilValue(currentShopApi)
}

export const useShopApi = (shopId: number) => {
  return useRecoilValue(shopApi(shopId))
}

export const useCurrentTenantApiV2 = () => {
  return useRecoilValue(tenantApiV2)
}

export const useCurrentCustomerApiV2 = () => {
  return useRecoilValue(currentCustomerApiV2)
}
